import React, { useEffect, useState } from "react";
import "./CreateFoodItems.css";
import { Button, CreateFoodHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { baseUrl, getUserDetails, userDetails } = useGlobalContext();
  const { country, symbol, restricted, status } = userDetails;

  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));
  const [wLoading, setWLoading] = useState(false);
  const [symbolLoading, setSymbolLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBonus, setLoadingBonus] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [resLoading, setresLoading] = useState(false);
  const [loadingOtpMessage, setLoadingOtpMessage] = useState(false);
  const [bonus, setbonus] = useState(false);
  const [profit, setprofit] = useState(false);
  const [withdrawalText, setwithdrawalText] = useState(false);
  const [wfLoading, setwfLoading] = useState(false);
  const [otpMessage, setotpMessage] = useState(false);
  const [otp, setotp] = useState("");
  const [conversionMessage, setconversionMessage] = useState("");
  const [conversionMessageLoader, setconversionMessageLoader] = useState(false);
  const [transferStep, setTransferStep] = useState("");
  const [loadingTransfer, setLoadingT] = useState(false);
  const notify = () => toast.success("User Details Updated");

  const navigateBack = () => {
    navigate(`/user-details/${id}`);
  };

  const handleBonus = () => {
    setLoadingBonus(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { bonus: bonus },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingBonus(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingBonus(false);
      });
  };
  const handleWFormat = () => {
    setwfLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { withdrawalText: withdrawalText },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setwfLoading(false);
        toast.success("Withdrawal Text Updated");
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setwfLoading(false);
      });
  };
  const handleTransferStep = () => {
    setLoadingT(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { transferStep: transferStep },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingT(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingT(false);
      });
  };
  const handleProfit = () => {
    setLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { profit: profit },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoading(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleOtp = () => {
    setLoadingOtp(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { userOtp: otp },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingOtp(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingOtp(false);
      });
  };

  const handleOtpMessage = () => {
    setLoadingOtpMessage(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { otpMessage: otpMessage },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingOtpMessage(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingOtpMessage(false);
      });
  };
  const handleConversionMessage = () => {
    setconversionMessageLoader(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { conversionMessage: conversionMessage },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setconversionMessageLoader(false);
        toast.success("Conversion Message updated");
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setconversionMessageLoader(false);
      });
  };

  const activateWithdrawal = () => {
    setWLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        {
          status: status === "true" ? "false" : "true",
        },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        if (data.status === 200) {
          if (status !== "true") {
            toast.success("Withdrawal Activated");
          } else {
            toast.success("Withdrawal Deactivated");
          }
          setWLoading(false);
          setTimeout(() => {
            navigateBack();
          }, 2000);
        }
      })
      .catch((error) => {
        setWLoading(false);
      });
  };
  const activateRestriction = () => {
    setresLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        {
          restricted: restricted === "true" ? "false" : "true",
        },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        if (data.status === 200) {
          toast.success(
            restricted === "false"
              ? "Account Restricted"
              : "Account Unrestricted"
          );
          setresLoading(false);
          setTimeout(() => {
            navigate("/users");
          }, 2000);
        }
      })
      .catch((error) => {
        setresLoading(false);
      });
  };

  const getSymbol = symbol == "" ? "$" : symbol === "$" ? country.symbol : "";

  const handleSymbol = () => {
    setSymbolLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        {
          symbol: getSymbol,
        },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        if (data.status === 200) {
          if (symbol !== "$") {
            toast.success("You Can Pay in Dollar");
          } else {
            toast.success("You Can Pay in their currency");
          }
          setSymbolLoading(false);
          setTimeout(() => {
            navigate("/user-details/" + userDetails._id);
          }, 2000);
        }
      })
      .catch((error) => {
        setSymbolLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails(id);
  }, []);

  return (
    <section className="add_food_item_container">
      <CreateFoodHeader
        btn={false}
        navigateBack={navigateBack}
        title={"Update User Details"}
      />
      <div className="add_food_item_form">
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Profit</p>
          <input
            type="text"
            name="profit"
            className="food_item_inputs"
            placeholder="Profit"
            onChange={(e) => setprofit(e.target.value)}
            defaultValue={userDetails.profit}
          />
        </div>
        <Button
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Profit"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleProfit}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Bonus</p>
          <input
            type="text"
            name="bonus"
            className="food_item_inputs"
            placeholder="Bonus"
            onChange={(e) => setbonus(e.target.value)}
            defaultValue={userDetails.bonus}
          />
        </div>
        <Button
          icon={
            loadingBonus && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Bonus"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleBonus}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP"
            onChange={(e) => setotp(e.target.value)}
            defaultValue={userDetails.userOtp}
          />
        </div>
        <Button
          icon={
            loadingOtp && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update OTP"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleOtp}
        />

        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP Message</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Message"
            defaultValue={userDetails.otpMessage}
            onChange={(e) => setotpMessage(e.target.value)}
          />
        </div>
        <Button
          icon={
            loadingOtpMessage && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Message"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleOtpMessage}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP Step</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Step"
            onChange={(e) => setTransferStep(e.target.value)}
            defaultValue={userDetails.transferStep}
          />
        </div>
        <Button
          icon={
            loadingTransfer && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Step"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleTransferStep}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Conversion Message</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Step"
            onChange={(e) => setconversionMessage(e.target.value)}
            defaultValue={userDetails.conversionMessage}
          />
        </div>
        <Button
          icon={
            conversionMessageLoader && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Conversion Message"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"100%"}
          height={45}
          navigate={handleConversionMessage}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Widthdrawal Text</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Step"
            onChange={(e) => setwithdrawalText(e.target.value)}
            defaultValue={userDetails.withdrawalText}
          />
        </div>
        <Button
          icon={
            wfLoading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Withdrawal Format"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleWFormat}
        />

        <Button
          color={"white"}
          title={
            wLoading
              ? "Loading..."
              : status === "false"
              ? "Activate Withdrawal"
              : "Deactivate Withdrawal"
          }
          navigate={activateWithdrawal}
        />
        <Button
          color={"white"}
          title={
            resLoading
              ? "Loading..."
              : restricted === "false"
              ? "Restrict Account"
              : "Unrestrict Account"
          }
          navigate={activateRestriction}
        />
        <Button
          color={"white"}
          title={
            symbolLoading
              ? "Loading..."
              : symbol === country?.symbol || symbol === ""
              ? "Pay $"
              : "Pay In Local Currency"
          }
          navigate={handleSymbol}
        />
      </div>
      <ToastContainer />
    </section>
  );
};

export default CreateFoodItems;
